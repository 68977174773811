#item_box {
    display: flex;
    justify-content: center;
    align-content: center;
}

#materias_box {
    display: grid;
    justify-items: start;
    justify-content: start;
}