.carousel-content {
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-out {
    opacity: 0;
  }
  
  .fade-in {
    opacity: 1;
  }
  