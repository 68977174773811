/* WhatsAppButton.css */
.whatsapp-button_float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366; /* Fondo sólido */
    border-radius: 50%;
    width: 40px;  /* Ajusta el tamaño */
    height: 40px; /* Ajusta el tamaño */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Sombra para mejor visibilidad */
  }
  
  .whatsapp-icon {
    color: white; /* Color del icono */
    font-size: 24px; /* Ajusta el tamaño del icono */
  }
  